:root {
  --background: #eff8ff;
  --background-alt: #edf2f7;
  --text: #3a3f58;
  --text-alt: #5a5d6a;
  --anchor: #1067b1;
  --icons: #4ea1e9;
  --accent: #6b879b;
  --tags: #0c4c82;
  --borders: #93938e;
  --shadows: #a8b1cf;
  --error: #ee6a59;
  --warn: #FCCC5B;
  --success: #acd5bd;
}

$BACKGROUND: var(--background);
$BACKGROUND-ALT: var(--background-alt);
$TEXT: var(--text);
$TEXT-ALT: var(--text-alt);
$ANCHOR: var(--anchor);
$ICONS: var(--icons);
$ACCENT: var(--accent);
$TAGS: var(--tags);
$BORDERS: var(--borders);
$SHADOWS: var(--shadows);
$ERROR: var(--error);
$WARN: var(--warn);
$SUCCESS: var(--success);


