@use "theme";

@import '@angular/cdk/overlay-prebuilt.css';


body {
  background: theme.$BACKGROUND;
  color: theme.$TEXT;
  margin: 0;
}

a {
  color: theme.$ANCHOR;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

input[type=text],
input[type=password],
input[type=url] {
  background: inherit;
  border: none;
  border-bottom: 1px solid transparent;
  color: theme.$TEXT;
  font-family: 'Fira Mono', monospace;
  font-size: 14px;
  line-height: 22px;
  outline: none;
  width: 100%;

  &::placeholder {
    color: theme.$TEXT;
  }

  &:focus {
    border-bottom: 1px solid theme.$ICONS;
  }
}

button {
  background: transparent;
  border: none;
  color: theme.$TEXT;
  cursor: pointer;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  outline: none;
  padding: 8px;
  text-transform: lowercase;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {

    text-decoration: underline;

  }

  &:hover:disabled {
    background: theme.$ERROR;
    color: theme.$TEXT-ALT;
    cursor: default;
  }

  &:disabled {
    background: theme.$BACKGROUND-ALT;
    color: theme.$TEXT-ALT;
  }

}
